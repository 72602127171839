<!-- 退款 -->
<template>
    <div class="container">
        <div class="container-content">

          <div class="noRefund" v-if="list.length == 0">
            <img src="https://cdn.qiumo.net.cn/allsmarketmaterial/taiyue_wxgzh/nodata.png"></img>
            <div>您没有可退款订单</div>
            <div class="num">如有疑问请咨询客服热线：400 009 9713</div>
          </div>
            <div class="content-home-list" v-else v-for="item in list">
                <div class="home-list-item">
                    <div class="item-top">
                        <div class="top-title">
                            <img src="https://cdn.qiumo.net.cn/allsmarketmaterial/lky_gzh/ceshi-refund.png">
                            <span>{{ item.item_long_name }}</span>
                        </div>
                        <div class="top-price">
                            <span>{{ item.item_price }}</span>
                        </div>
                    </div>
                    <div class="item-bottom">
                        <div class="bottom-time">
                            <span>交易时间 {{ item.create_time }}</span>
                            <span>交易单号 {{ item.pay_id }}</span>
                        </div>
                        <div class="bottom-btn">
                            <button @click="handleRefund(item)">退款</button>
                            <!-- <button v-if="item.refunded == null" @click="handleRefund(item)">退款</button>
                            <button v-if="item.refunded == false" @click="haveRefund()">退款中</button> -->
                        </div>
                    </div>
                </div>
            </div>

        </div>


        <v-alert
      		:visibility.sync="alert.visibility"
          :title="alert.title"
          :message="alert.message"
          :rulestype="alert.rulestype"
          @handleSubmit="handleSubmit"
          />

    </div>
</template>
  
  <script>
  import VAlert from "./record-alert/alert.vue";
  import { getRefundable ,refundSingle} from "@/api/lky_gzh/index.js";
  import { mapGetters,mapMutations } from "vuex";
  
  export default {
    components: {
      VAlert
    },
    data() {
      return {
        alert: {
          visibility: false,
          title: "",
          message: "",
        },
        state: false,
        list:[],
        buyItemStatusTip: '',
      };
    },
    computed: {
      ...mapGetters(["lky_token", "lky_user_info","lky_wechat_open_id"]),
    },
    created() {
      this.refundableList();
    },
    methods: {
      // 可退款列表
      async refundableList() {
        this.list = [];
        this.$toast.loading({
          duration: 0,
          forbidClick: true,
          message: "加载中...",
        });
        await getRefundable(this.lky_token).then(res =>{
          if (res.code == 0) {
            this.list = res.record;
            console.log("可退款订单=",this.list)
          }

          this.$toast.clear();
        })
      },

      // 退款
      handleRefund(item){
        this.$toast.loading({
          duration: 0,
          forbidClick: true,
          message: "加载中...",
        });
        refundSingle(this.lky_token,{'trade_no': item.pay_id}).then((res)=>{
          if (res.code == 0) {
            this.buyItemStatusTip = '发起退款成功';
            this.alert = {
              visibility: true,
              message: res.message,
              title: this.buyItemStatusTip,
            };
            setTimeout(() => {
              this.refundableList();
            }, 2000)
          } else {
            this.buyItemStatusTip = '发起退款失败';
            this.alert = {
              visibility: true,
              message: res.message,
              title: this.buyItemStatusTip,
            };
          }
          this.$toast.clear();
        }).catch((err)=>{
          this.$toast.clear();
        })
      },
      handleSubmit(){
        this.visibility = false;
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .container{
    width: 100%;
    height: 100%;
    background-color: #F7F7F7;

    .container-content{
        padding-top: 60px;
        width: 100%;
        height: 100%;
    }
  }

  .content-home-list{
    display: flex;
    justify-content: center;
    align-items: center;
    .home-list-item{
        width: 85%;
        padding: 20px;
        border-radius: 16px;
        background: #FFF;
        box-shadow: 0px 1.744px 3.488px 0px rgba(0, 0, 0, 0.15);
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;

        .item-top{
            display: flex;
            justify-content: space-between;
            align-items: center;
            .top-title{
                img{
                    width: 72px;
                    height: 72px;
                    margin-right: 20px;
                }
                span{
                    color: #000;
                    font-size: 26px;
                }
            }
            .top-price{
                span{
                    color: #000;
                    font-size: 26px;
                }
            }
        }
        .item-bottom{
            margin-top: 30px;
            display: flex;
            justify-content: space-between;
            align-items: end;

            .bottom-time{
                display: flex;
                flex-direction: column;
                color: #000;
                font-size: 20px;
                span{
                    padding: 10px 0;
                    white-space: nowrap;
                }
            }
            .bottom-btn{
                button{
                    margin-bottom: 10px;
                    width: 100px;
                    height: 48px;
                    background-color: transparent;
                    border-radius: 4px;
                    border: 1px solid #FFB21B;
                    color: #FFB21B;
                    font-size: 26px;
                }
            }
        }
    }
  }


  // 没有退款订单的样式
  .noRefund{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    
    img{
      width: 354px;
      margin-top: 170px;
    }
    div{
      // width: 100%;
      height: 50px;
      font-size: 30px;
      color: #999999;
      font-size: 28px;
    }
    .num{
      font-size: 24px;
    }
  }
  </style>